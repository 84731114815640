import React, { useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import {Swiper as SwiperType} from 'swiper';
import 'swiper/css/bundle'
import styles from '../styles/pages/Login.module.scss';
import { Input } from '../components/core/Input';
import { useAuth } from '../components/auth/AuthProvider';
import { signIn } from '../api/auth';
import { useToast } from '../components/core/ToastManager';
import { ApiErrorResponse } from '../types/api';
import { useUserProfile } from '../hooks/useUserProfile';
import Spinner from '../components/core/Spinner';
import ErrorInputIcon from '../assets/icons/error-input.svg';
import { useSignInPromotions } from '../hooks/useBoutiques';
import SliderArrow from '../assets/icons/slider-arrow.svg';
import FallbackPromoImage from '../assets/images/head-massage.jpg';

interface FormData {
  email: string;
  password: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required')
});

const Login: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const swiperRef = useRef<SwiperType>();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location');
  const isBookingRedirect = searchParams.get('booking') === 'true';
  const registerUrl = isBookingRedirect ? `/register?location=${locationId}&booking=true` : '/register';
  const { data: userProfile, isLoading: isUserProfileLoading, error: userProfileError } = useUserProfile();
  const { data: signInPromotions, isLoading: isSignInPromotionsLoading, error: signInPromotionsError } = useSignInPromotions();

  useEffect(() => {
    if (!isUserProfileLoading && !userProfileError && userProfile?.data.email) {
      navigate('/dashboard', { replace: true });
    }
  }, [userProfile, isUserProfileLoading, userProfileError, navigate]);

  const mutation = useMutation({
    mutationFn: (data: FormData) => signIn(data.email, data.password),
    onSuccess: (data) => {
      login(data.headers);
      addToast('Successfully signed in', 'success');

      const redirectPath = isBookingRedirect
        ? `/book?location=${locationId}&restoreSession=true`
        : localStorage.getItem('redirectPath') || '/dashboard';
      localStorage.removeItem('redirectPath');

      navigate(redirectPath, { replace: true });
    },
    onError: (error: ApiErrorResponse) => {
      addToast(error.errorMessages[0] || 'An error occurred during sign in', 'error');
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutation.mutate(data);
  };

  const hasSlides = signInPromotions?.data.signInPromotions && signInPromotions?.data.signInPromotions.length > 1;
  const singlePromoSlide = signInPromotions?.data.signInPromotions[0]

  if (isUserProfileLoading) return <Spinner />;

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPageContent}>
        {isSignInPromotionsLoading ? (
          <Spinner />
        ) : (
          <Swiper
            modules={[Pagination]}
            onBeforeInit={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
            pagination={{
              type: 'progressbar',
            }}
            direction='horizontal'
            loop
            slidesPerView={1}
            className={styles.promoSlider}
          >
            {hasSlides ? signInPromotions?.data.signInPromotions.map((promotion, index) => (
              <SwiperSlide key={promotion.name + index + promotion.name}>
                <div className={styles.promoSlide}>
                  <img className={styles.promoImage} src={promotion.featuredImageUrl || FallbackPromoImage} alt={promotion.promotionTitle} />
                  <h1>{promotion.name}</h1>
                  <p className={styles.contentCopy}>{promotion.promotionTitle}</p>
                  {promotion.ctaButtonLink && <Link to={promotion.ctaButtonLink} target="_blank" className="button inverted">
                    {promotion.ctaButtonLabel}
                  </Link>}
                  <p className={styles.contentSubCopy}>{promotion.promotionCopy}</p>
                </div>
              </SwiperSlide>
            )) : (
              <div className={styles.promoSlide}>
                <img className={styles.promoImage} src={singlePromoSlide?.featuredImageUrl || FallbackPromoImage} alt={singlePromoSlide?.promotionTitle} />
                <h1>{singlePromoSlide?.name}</h1>
                <p className={styles.contentCopy}>{singlePromoSlide?.promotionTitle}</p>
                {singlePromoSlide?.ctaButtonLink && <Link to={singlePromoSlide?.ctaButtonLink} target="_blank" className="button inverted">
                  {singlePromoSlide?.ctaButtonLabel}
                </Link>}
                <p className={styles.contentSubCopy}>{singlePromoSlide?.promotionCopy}</p>
              </div>
            )}
            {hasSlides && <div className={styles.sliderArrows}>
              <div onClick={() => swiperRef.current?.slidePrev()} className={styles.swiperButtonPrev}>
                <img src={SliderArrow} alt="Previous slide" />
              </div>
              <div onClick={() => swiperRef.current?.slideNext()} className={styles.swiperButtonNext}>
                <img src={SliderArrow} alt="Next slide" />
              </div>
            </div>}
          </Swiper>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Sign In to Your Account</h2>
        <Input
          id="email"
          label="Email Address"
          inputProps={{ ...register('email'), autoComplete: 'username' }}
          error={errors.email?.message}
        />
        <Input
          id="password"
          label="Password"
          inputProps={{
            ...register('password'),
            type: 'password',
            autoComplete: 'current-password',
          }}
          error={errors.password?.message}
        />
        <Link className="button__underline" to="/forgot-password">
          Forgot Password?
        </Link>
        <button
          className={`button ${styles.buttonLogin} ${!isValid || mutation.isPending ? 'disabled' : ''}`}
          disabled={!isValid || mutation.isPending}
          type="submit"
        >
          {mutation.isPending ? 'Signing in...' : 'Sign in'}
        </button>
        {mutation.isError && (
          <p className={styles.errorMessage}>
            <img src={ErrorInputIcon} alt="error" />
            {mutation.error.errorMessages[0]}
          </p>
        )}
        <p className={styles.privacyPolicy}>
          By signing in, you agree to the{' '}
          <Link to="https://thenowmassage.com/terms-of-service" target="_blank">
            Terms of Use
          </Link>{' '}
          and acknowledge the{' '}
          <Link to="https://thenowmassage.com/privacy-policy" target="_blank">
            Privacy Policy.
          </Link>
        </p>
        <div className={styles.separator}></div>
        <h3>New to The NOW Massage?</h3>
        <Link className={`button inverted ${styles.createAccountLink}`} to={registerUrl}>
          Create an Account
        </Link>
        <div className={styles.newSiteCard}>
          <div className={styles.newSiteCardText}>
            <h2>Welcome to our Elevated New Site!</h2>
            <p>
              If you experience any issues or need assistance with accessing your account or booking, please contact the
              boutique.
            </p>
          </div>
          <Link
            className={`button inverted ${styles.locationButton}`}
            to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/boutiques`}
          >
            Find A location
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
