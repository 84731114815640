import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import styles from '../styles/pages/BookingMobile.module.scss'
import { generateStepGroups } from '../constants/bookingSteps';
import { useBoutique, useBoutiquePromotions } from '../hooks/useBoutiques'
import { useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { useServices, useTherapists } from '../hooks/useBookings';
import { useToast } from '../components/core/ToastManager';
import Modal from '../components/core/Modal';
import { BookingFlowGuests } from '../components/BookingFlowGuests';
import { BookingFlowDuration } from '../components/BookingFlowDuration';
import { BookingFlowPressure } from '../components/BookingFlowPressure';
import { BookingFlowEnhancements } from '../components/BookingFlowEnhancements';
import { BookingFlowAppointment } from '../components/BookingFlowAppointment';
import { BookingFlowMassage } from '../components/BookingFlowMassage';
import MapPinIcon from '../assets/icons/map-pin-new.svg'
import PhoneIcon from '../assets/icons/phone.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import ClockIcon from '../assets/icons/clock.svg'
import MassageIcon from '../assets/icons/appointments.svg'
import { Boutique } from '../types/boutiques';
import { Service, ServiceRestriction, Therapist } from '../types/bookings';
import { useQueryClient } from '@tanstack/react-query';
import LocationSearch from '../components/LocationSearch';
import { formatPhoneNumber } from '../utils/formatPhone';
import Spinner from '../components/core/Spinner';
import ErrorScreen from '../components/core/ErrorMessages';
import { DateTime } from 'luxon';
import { pushToDataLayer } from '../utils/tracking';
import { globalStyles } from '../constants/styles';


export interface Guest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender?: string;
  minor?: boolean;
  pregnant?: string;
  selection?: string;
}

export interface UserOptions {
  minor?: boolean
  gender?: string
  pregnant?: string
}

interface GuestsForm {
  userPregnancy?: string;
  userMinority?: string;
  guests?: Guest[];
}

export interface GuestSelection {
  duration?: number;
  pressure?: string;
  createMembership?: boolean;
  massage?: Service
  enhancements?: Service[]
  therapist?: Therapist | null
}

const guestSchema = yup.object().shape({
  firstName: yup.string()
    .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
    .max(32, 'First name cannot be longer than 32 characters')
    .required('First name is required'),
  lastName: yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
    .max(32, 'Last name cannot be longer than 32 characters')
    .required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string()
    .matches(/^\d{3}\.\d{3}\.\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
  selection: yup.string(),
});

const schema = yup.object().shape({
  userSelection: yup.string(),
  guests: yup.array().of(guestSchema).when([], {
    is: (guests: Guest[]) => guests?.length > 0,
    then: (schema) => schema.required('Guest information is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});


export const BookingMobile: React.FC = () => {
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location')
  const promotionId = searchParams.get('promotionId')
  // data for preselected next appointment from HubSpot
  const isHubspotRedirect = searchParams.get('guest') === 'solo'
  const preselectedPressureString = searchParams.get('pressure')
  const preselectedPressure = preselectedPressureString === 'light' ? 'medium' : 'deep'
  const preselectedTherapistId = searchParams.get('therapistId')
  const preselectedAddOnsIds = searchParams.get('addOns')?.split(',') || []
  const preselectedServiceId = searchParams.get('service')
  const preselectedTime = searchParams.get('time')

  const isHubspotDataValid = isHubspotRedirect && preselectedServiceId && preselectedServiceId !== 'undefined' && preselectedTime && preselectedTime !== 'undefined' && locationId;

  const restoreSession = searchParams.get('restoreSession') === 'true'
  const restoreSessionFromDuration = searchParams.get('restoreSessionFromDuration') === 'true'
  const { addToast } = useToast()
  const navigate = useNavigate();
  const queryClient = useQueryClient()


  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const boutiqueId = locationId || userProfile?.data.boutiqueId || 'null'
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId)
  const [selectedBoutique, setSelectedBoutique] = useState<Boutique | undefined>(boutique?.data || undefined);
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(selectedBoutique?.id || boutiqueId);
  const { data: userMemberships, isLoading: isLoadingMemberships } = useUserMemberships(true, !!userProfile);
  const { data: boutiquePromotions } = useBoutiquePromotions(selectedBoutique?.id || boutiqueId, 'product_service_promotions');
  const { data: therapists, isLoading: isLoadingTherapists } = useTherapists(selectedBoutique?.id || boutiqueId);
  const activePromotion = boutiquePromotions?.data.promotions.find(promotion => promotion.id.toString() === promotionId)
  const defaultPromotion = selectedBoutique?.offerBanner
  const promo = activePromotion?.description || defaultPromotion

  const preselectedService = services?.services.find(service => service.zenotiId === preselectedServiceId)
  const preselectedDuration = services?.durations.find(duration => duration.serviceDurationMinutes === preselectedService?.duration)?.displayDurationMinutes
  const preselectedEnhancements: Service[] = preselectedAddOnsIds
    .map(addOnId => services?.services.find(service => service.id === Number(addOnId)))
    .filter((service): service is Service => service !== undefined);
  const preselectedTherapist = therapists?.therapists.find(therapist => therapist.id === preselectedTherapistId)

  const [isPregnancyModalOpen, setIsPregnancyModalOpen] = useState(false)
  const [activeGuestIndex, setActiveGuestIndex] = useState(0)
  const [userOptions, setUserOptions] = useState<UserOptions | null>(null)
  const [userSelections, setUserSelections] = useState<GuestSelection | null>(
    isHubspotDataValid
      ? {
        duration: preselectedDuration,
        pressure: preselectedPressure || undefined,
        createMembership: false,
        massage: preselectedService,
        enhancements: preselectedEnhancements,
        therapist: preselectedTherapist,
      }
      : null
  );
  const [guestsSelections, setGuestsSelections] = useState<GuestSelection[] | null>(null)
  const [guestsInfo, setGuestsInfo] = useState<Guest[]>([]);
  const [checkoutLink, setCheckoutLink] = useState('')
  const [showLocationSearch, setShowLocationSearch] = useState(false)
  const [currentStepId, setCurrentStepId] = useState<string>('guests');
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [initialEnhancements, setInitialEnhancements] = useState<{
    host: Service[] | null;
    guests: (Service[] | null)[];
  }>({
    host: userSelections?.enhancements || null,
    guests: guestsSelections?.map((guest) => guest?.enhancements || null) || [],
  });
  // state to track if the user has selected pregnancy/minor option but not selected the sub option
  const [optionsErrorStates, setOptionsErrorStates] = useState<{ [id: string]: boolean }>({});

  // Dynamically generate steps and labels based on current step and guests
  const stepGroups = generateStepGroups(guestsInfo);

  const { control, handleSubmit, unregister, setError, watch, resetField, clearErrors, formState: { errors, isValid } } = useForm<GuestsForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      userMinority: userOptions?.gender || '',
      userPregnancy: userOptions?.pregnant || '',
      guests: guestsInfo.length > 0 ? guestsInfo.map(guest => ({
        firstName: guest.firstName,
        lastName: guest.lastName,
        email: guest.email,
        phone: guest.phone,
        gender: guest.gender,
        pregnant: guest.pregnant
      })) : [],
    },
  });

  const clearErrorsForGuest = () => {
    resetField('guests')
    clearErrors('guests')
  }

  const userExtraOptions = watch('userPregnancy');
  const guestsExtraOptions = watch('guests') || [];
  const hasPregnancyIssue =
    userExtraOptions === 'under12weeks' ||
    guestsExtraOptions.some((guest: Guest) => guest.pregnant === 'under12weeks');

  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showRestoreDataModal, setShowRestoreDataModal] = useState(false)
  const [showEnhancementsPopUp, setShowEnhancementsPopUp] = useState(false)
  const [skipEnhancements, setSkipEnhancements] = useState(false)
  const [addedEnhancementsOnCheckout, setAddedEnhancementsOnCheckout] = useState(false)
  const [isLinkLoading, setIsLinkLoading] = useState(false)
  const [boutiqueWithinRadius, setBoutiqueWithinRadius] = useState<Boutique | null>(null)
  const [expandedGroupIndex, setExpandedGroupIndex] = useState<number | null>(null);

  const hasGuests = guestsInfo.length > 0;


  // Check if a step is clickable (highlighted if completed or current step)
  const isStepClickable = (stepId: string) => completedSteps.includes(stepId) || stepId === currentStepId;

  const handleGroupClick = (groupIndex: number) => {
    if (hasGuests) {
      setExpandedGroupIndex(groupIndex);
    }
  };

  const handleStepClick = (stepId: string) => {
    if (isStepClickable(stepId)) {
      handleStepSelect(stepId);
    }
  };

  // Helper function to get the display value for each step
  const getStepValue = (stepId: string): string | null => {
    if (stepId.startsWith('duration')) {
      const selection = stepId === 'duration-host' ? userSelections : guestsSelections ? guestsSelections[parseInt(stepId.split('-').pop()!) - 1] : null;
      return selection?.duration ? `${selection.duration} Min` : null;
    }
    if (stepId.startsWith('pressure')) {
      const selection = stepId === 'pressure-host' ? userSelections : guestsSelections ? guestsSelections[parseInt(stepId.split('-').pop()!) - 1] : null;
      if (selection?.pressure === 'medium') return 'Light to Medium'
      if (selection?.pressure === 'deep') return 'Firm to Deep'

    }
    if (stepId.startsWith('massage')) {
      const selection = stepId === 'massage-host' ? userSelections : guestsSelections ? guestsSelections[parseInt(stepId.split('-').pop()!) - 1] : null;
      return selection?.massage?.name || null;
    }
    if (stepId.startsWith('enhancements')) {
      const selection = stepId === 'enhancements-host' ? userSelections : guestsSelections ? guestsSelections[parseInt(stepId.split('-').pop()!) - 1] : null;
      return selection?.enhancements ? `${selection.enhancements.length} selected` : null;
    }
    if (stepId === 'appointment') {
      return null;
    }
    return null;
  };

  const openEnhancementsModal = () => {
    setShowEnhancementsPopUp(true);
    document.body.style.overflow = 'hidden';
  }

  const closeEnhancementsModal = () => {
    setShowEnhancementsPopUp(false);
    document.body.style.overflow = 'auto';
  }

  const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(!!isHubspotDataValid);

  const formatPreselectedTime = (time: string): string => {
    // Parse the ISO string into a Luxon DateTime object
    const date = DateTime.fromISO(time);

    // Format the date to display weekday, hour, and minute
    return date.toLocaleString({
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const preselectedDateTimeLabel = preselectedTime ? formatPreselectedTime(preselectedTime) : "";
  const preselectedTimeLabel = preselectedTime ? DateTime.fromISO(preselectedTime).toLocaleString(DateTime.TIME_SIMPLE) : "";

  const handleModalClose = () => {
    setIsHubspotModalOpen(false);
  };

  useEffect(() => {
    if (!isLoadingBoutique && (boutiqueError || !boutique) && !selectedBoutique && ((!isLoadingUserProfile && !userProfile) || (userProfileError && !userProfile) || (userProfile && !userProfile.data.boutiqueId))) {
      setShowLocationSearch(true)
    }
    if (selectedBoutique?.useOldSite && selectedBoutique?.oldBookingUrl) {
      window.location.href = selectedBoutique.oldBookingUrl
    }
    queryClient.invalidateQueries({ queryKey: ['therapists'] });
  }, [selectedBoutique, boutiqueId, isLoadingBoutique, userProfile, userProfileError, isLoadingUserProfile])

  // Effect to clear errors if pregnant option is unselected
  useEffect(() => {
    if (!hasPregnancyIssue) {
      clearErrors('userPregnancy');
      guestsExtraOptions.forEach((_, index) => {
        clearErrors(`guests.${index}.pregnant`);
      });
    }
  }, [hasPregnancyIssue, clearErrors]);

  useEffect(() => {
    if (!isLoadingBoutique && !boutiqueError && boutique?.data.id) {
      setSelectedBoutique(boutique?.data)
    }
  }, [boutique])

  // Saving entered data to a session storage

  useEffect(() => {
    if (restoreSession) {
      const redirectCheckout = sessionStorage.getItem('unauthCheckoutLink')
      if (redirectCheckout) {
        navigate(redirectCheckout)
      }
    }
  }, [])

  useEffect(() => {
    if (!userSelections && !guestsSelections && !userOptions && guestsInfo.length === 0 && !restoreSession && userProfile) {
      const savedData = sessionStorage.getItem('bookingData');
      if (savedData) setShowRestoreDataModal(true)
    }
    if (!userProfile && !isLoadingUserProfile) {
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
    }
    if (userProfile && (restoreSession || restoreSessionFromDuration)) {
      loadDataFromSessionStorage()
      if (restoreSession) {
        setCurrentStepId("appointment")
      } else {
        setCurrentStepId("duration-host")
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (showEnhancementsPopUp) {
      const isMandatory = (enhancement: Service) => {
        return (
          enhancement.restrictions.some(
            (r) => r.type === 'pregnancy_required'
          ) && userOptions?.pregnant
        );
      };

      setInitialEnhancements({
        host: userSelections?.enhancements?.filter(isMandatory) || [],
        guests: guestsSelections?.map((guest) =>
          guest?.enhancements?.filter(isMandatory) || []
        ) || [],
      });
    }
  }, [showEnhancementsPopUp, userSelections, guestsSelections, userOptions]);

  useEffect(() => {
    if (isHubspotModalOpen === false && isHubspotDataValid) {
      setUserSelections({
        duration: preselectedDuration,
        pressure: preselectedPressure || undefined,
        createMembership: false,
        massage: preselectedService,
        enhancements: preselectedEnhancements,
        therapist: preselectedTherapist,
      })
      setCurrentStepId("enhancements-host");
      setCompletedSteps(["guests", "duration-host", "pressure-host", "massage-host"]);
    }
  }, [isHubspotModalOpen, isHubspotDataValid]);


  const saveDataToSessionStorage = () => {
    const dataToSave = {
      userSelections,
      guestsSelections,
      userOptions,
      guestsInfo,
      completedSteps,
      currentStepId
    };

    sessionStorage.setItem('bookingData', JSON.stringify(dataToSave));
  };

  const loadDataFromSessionStorage = () => {
    const savedData = sessionStorage.getItem('bookingData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setUserSelections(parsedData.userSelections || {});
      setGuestsSelections(parsedData.guestsSelections || []);
      setUserOptions(parsedData.userOptions || {});
      setGuestsInfo(parsedData.guestsInfo || []);
      setCompletedSteps(parsedData.completedSteps || [])
      setCurrentStepId(parsedData.currentStepId)
    }
  };

  const clearBookingDataFromSessionStorage = () => {
    sessionStorage.removeItem('bookingData');
  };

  const addGuest = () => {
    if (services?.bookingOptions.maxGuests && guestsInfo.length < services?.bookingOptions.maxGuests) {
      setGuestsInfo([...guestsInfo, {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      }]);
      setActiveGuestIndex(guestsInfo.length)
    } else {
      addToast('You have reached the limit of guests', 'warning', 4500)
    }
  };

  const removeGuest = (index: number) => {
    unregister(`guests.${index}`);
    setGuestsInfo((prevGuests) => prevGuests.filter((_, i) => i !== index));
    if (guestsSelections && guestsSelections.length > 0) {
      setGuestsSelections((prevSelections) => prevSelections?.filter((_, i) => i !== index) || []);
    }
    if (guestsInfo.length > 1) {
      setActiveGuestIndex(guestsInfo.length - 2)
    }
  };

  const isLastEnhancementStep = (): boolean => {
    // Determine if the current step is the last enhancement step for the host or guests
    if (currentStepId === 'enhancements-host' && guestsInfo.length === 0) {
      // Only the host has enhancements, and we've finished the host's step
      return true;
    }

    if (currentStepId.startsWith('enhancements-guest')) {
      const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
      // If it's the last guest's enhancement step
      return guestIndex === guestsInfo.length - 1;
    }

    return false;
  };

  const onSubmit = (data: FieldValues) => {
    // GTM tracking
    if (currentStepId === 'guests') {
      if (data.guests && data.guests.length > 0) {
        pushToDataLayer({
          event: 'group',
          workflowName: 'booking',
          action: 'buttonClick',
        })
      } else {
        pushToDataLayer({
          event: 'justMe',
          workflowName: 'booking',
          action: 'buttonClick',
        })
      }

      if (data.userPregnancy || data.guests.some((guest: Guest) => guest.pregnant)) {
        pushToDataLayer({
          event: 'specialSelection',
          specialSelectionValue: `${data.userPregnancy ? `host: ${data.userPregnancy}` : ''}, ${data.guests.map((guest: Guest, index: number) => guest.pregnant ? `guest-${index + 1}: ${guest.pregnant}` : '').join('')}`,
          workflowName: 'booking',
          action: 'buttonClick',
        })
      }

      if (data.userMinority || data.guests.some((guest: Guest) => guest.gender)) {
        pushToDataLayer({
          event: 'specialSelection',
          specialSelectionValue: `${data.userMinority ? `host: ${data.userMinority}` : ''}, ${data.guests.map((guest: Guest, index: number) => guest.gender ? `guest-${index + 1}: ${guest.gender}` : '').join('')}`,
          workflowName: 'booking',
          action: 'buttonClick',
        })
      }

    }

    if (currentStepId === 'duration-host' || currentStepId.startsWith('duration-guest')) {
      pushToDataLayer({
        event: 'durationSelection',
        workflowName: 'booking',
        action: 'buttonClick',
      })
    }

    if (currentStepId === 'pressure-host' || currentStepId.startsWith('pressure-guest')) {
      pushToDataLayer({
        event: 'pressureSelection',
        workflowName: 'booking',
        action: 'buttonClick',
      })
    }

    if (currentStepId === 'massage-host' || currentStepId.startsWith('massage-guest')) {
      pushToDataLayer({
        event: 'massageSelection',
        workflowName: 'booking',
        action: 'buttonClick',
      })
    }

    if (currentStepId === 'enhancements-host' || currentStepId.startsWith('enhancements-guest')) {
      pushToDataLayer({
        event: 'enhancementSelection',
        workflowName: 'booking',
        action: 'buttonClick',
      })
    }

    if (currentStepId === 'guests' && data.guests) {
      const formattedGuests = (data.guests.filter((guest: Guest) => !!guest) || []);

      setGuestsInfo(formattedGuests);
      const selectedUserOptions = {
        gender: data.userMinority,
        pregnant: data.userPregnancy
      };
      setUserOptions(selectedUserOptions);

      if (formattedGuests.length > 0) {
        const emailCounts: Record<string, number> = {};
        formattedGuests.forEach((guest: Guest) => {
          if (guest.email) {
            emailCounts[guest.email] = (emailCounts[guest.email] || 0) + 1;
          }
        });

        const duplicateEmails = Object.keys(emailCounts).filter((email) => emailCounts[email] > 1);
        if (duplicateEmails.length > 0) {
          // Set errors for each duplicate email field
          duplicateEmails.forEach((duplicateEmail) => {
            formattedGuests.forEach((guest: Guest, index: number) => {
              if (guest.email === duplicateEmail) {
                setError(`guests.${index}.email`, {
                  type: 'manual',
                  message: 'This email is already used by another guest',
                });
              }
            });
          });
          return; // Prevent submission if there are duplicate emails
        }
      }
    }

    setCompletedSteps(prev => [...prev, currentStepId]);
    if (isLastEnhancementStep()) {
      saveDataToSessionStorage();
      setCurrentStepId('appointment');
    } else if (currentStepId === 'appointment' && checkoutLink) {
      const hasRestriction = (restrictions: ServiceRestriction[], type: string) =>
        restrictions.some(restriction => restriction.type === type);

      const isRequiredEnhancement = (enhancement: Service, isPregnant: boolean, isDeepPressure: boolean) => {
        return (
          (isPregnant && hasRestriction(enhancement.restrictions, 'pregnancy_required')) ||
          (isDeepPressure && hasRestriction(enhancement.restrictions, 'heavy_pressure_required'))
        );
      };

      // Filter mandatory enhancements for the host
      const mandatoryHostEnhancements = services?.services.filter(service =>
        isRequiredEnhancement(service, !!userOptions?.pregnant, userSelections?.pressure === 'deep')
      );

      // Check if host has selected enhancements and whether they are all mandatory
      const hasHostSelectedMandatoryOnly = userSelections?.enhancements?.every(selected =>
        mandatoryHostEnhancements?.some(mandatory => mandatory.id === selected.id)
      );

      // Filter mandatory enhancements for each guest
      const mandatoryGuestEnhancements = guestsInfo.map((guest, index) => {
        const guestPressure = guestsSelections?.[index]?.pressure === 'deep';
        return services?.services.filter(service =>
          isRequiredEnhancement(service, !!guest.pregnant, guestPressure)
        );
      });

      // Check if each guest has selected enhancements and whether they are all mandatory
      const hasGuestSelectedMandatoryOnly = guestsSelections?.every((guestSelection, index) =>
        guestSelection?.enhancements?.every(selected =>
          mandatoryGuestEnhancements?.[index]?.some(mandatory => mandatory.id === selected.id)
        )
      );

      // Determine if the popup should be shown
      const shouldShowPopup = ((hasHostSelectedMandatoryOnly || !userSelections?.enhancements?.length) ||
        (hasGuestSelectedMandatoryOnly || guestsSelections?.every(guest => !guest.enhancements?.length))) && !showEnhancementsPopUp;

      // saveDataToSessionStorage();

      if (shouldShowPopup && !skipEnhancements) {
        openEnhancementsModal()
        return;
      } else if (addedEnhancementsOnCheckout) {
        return
      }
      else {
        if (!userProfile) {
          sessionStorage.setItem('unauthCheckoutLink', checkoutLink)
          pushToDataLayer({
            event: 'bookingReview',
            workflowName: 'booking',
            action: 'buttonClick',
          })
          navigate(`/login?location=${selectedBoutique?.id}&booking=true`);
        } else {
          pushToDataLayer({
            event: 'bookingReview',
            workflowName: 'booking',
            action: 'buttonClick',
          })
          navigate(checkoutLink);
        }
      }
    }
    else {
      goToNextStep();
    }
  };

  const triggerSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handlePregnancySelection = (index?: number) => {
    setIsPregnancyModalOpen(true)
    const fieldId: `guests.${number}.pregnant` | "userPregnancy" = index !== undefined ? `guests.${index}.pregnant` : "userPregnancy";
    setError(fieldId, {
      type: 'manual',
      message: 'Please call the studio to book your appointment'
    });
  }

  const goToNextStep = () => {
    // If the current step is "guests", move to the first step in the first group
    if (currentStepId === "guests") {
      const firstGroup = stepGroups[0];
      if (firstGroup && firstGroup.steps.length > 0) {
        setCurrentStepId(firstGroup.steps[0].id); // Move to the first step in the first group
        setCompletedSteps(prev => [...prev, "guests"]); // Mark "guests" step as completed
      }
      return; // Exit early since we handled the "guests" step
    }

    // Find the index of the current step
    const stepIndex = stepGroups.findIndex(group =>
      group.steps.some(step => step.id === currentStepId)
    );

    // If we couldn't find the current step, return early
    if (stepIndex === -1) {
      console.error("Current step not found in step groups");
      return;
    }

    const currentGroup = stepGroups[stepIndex];
    const currentStepInGroupIndex = currentGroup.steps.findIndex(step => step.id === currentStepId);

    let nextStep = null;

    // If there's another step within the current group, go to that
    if (currentStepInGroupIndex < currentGroup.steps.length - 1) {
      nextStep = currentGroup.steps[currentStepInGroupIndex + 1];
    } else {
      // Otherwise, move to the first step in the next group, if it exists
      const nextGroup = stepGroups[stepIndex + 1];
      if (nextGroup && nextGroup.steps.length > 0) {
        nextStep = nextGroup.steps[0];
      }
    }

    // If there's a valid next step, navigate to it
    if (nextStep) {
      setCurrentStepId(nextStep.id); // Move to the next step
      setCompletedSteps(prev => [...prev, currentStepId]); // Mark current step as completed
    } else {
      console.warn("No more steps to navigate to");
    }
  };

  // Handle step selection (only if the step is completed)
  const handleStepSelect = (stepId: string) => {
    if (completedSteps.includes(stepId)) {
      setCurrentStepId(stepId); // Allow the user to go back to completed steps
    }
  };

  // Function to store the selected duration and optional membership flag for the user
  const handleUserDurationSelect = (duration: number, createMembership = false) => {
    setUserSelections(() => ({ duration, createMembership }));
  };

  // Function to store the selected duration for a guest
  const handleGuestDurationSelect = (duration: number, index: number) => {
    const updatedSelections = guestsSelections ? [...guestsSelections] : [];
    updatedSelections[index] = { duration };
    setGuestsSelections(updatedSelections);
  };

  const getNextStepLabel = (): string => {
    if (currentStepId === 'appointment') {
      return "Checkout";
    }

    if (isLastEnhancementStep()) {
      return "Appointment";
    }
    // Find the index of the current step group
    const stepGroupIndex = stepGroups.findIndex(group =>
      group.steps.some(step => step.id === currentStepId)
    );

    // If current step is not found in any group, assume it's the start
    if (stepGroupIndex === -1) {
      const firstGroup = stepGroups[0];
      if (firstGroup && firstGroup.steps.length > 0) {
        return firstGroup.steps[0].label;
      }
      return "Step not found";
    }

    const currentGroup = stepGroups[stepGroupIndex];
    const currentStepIndex = currentGroup.steps.findIndex(step => step.id === currentStepId);

    // If current step is not found within the current group, assume it's the start
    if (currentStepIndex === -1) {
      return stepGroups[0].steps[0].label;
    }

    // Check if there's a next step within the same group
    if (currentStepIndex < currentGroup.steps.length - 1) {
      return currentGroup.steps[currentStepIndex + 1].label;
    }

    // If this is the last step in the current group, check the next group
    const nextGroup = stepGroups[stepGroupIndex + 1];
    if (nextGroup && nextGroup.steps.length > 0) {
      return nextGroup.steps[0].label;
    }

    return "Checkout";
  };

  // Conditionally call the correct selection handler based on whether it's the user or a guest
  const onDurationSelect = (duration: number, createMembership?: boolean) => {
    if (currentStepId === 'duration-host') {
      handleUserDurationSelect(duration, createMembership);
      triggerSubmit()
    } else {
      const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1; // Extract guest index from step id
      handleGuestDurationSelect(duration, guestIndex);
      triggerSubmit()
    }
  };

  const hideContinueButton = () => {
    let hasMembership = false
    if (userMemberships?.membershipGroups) {
      for (const group of userMemberships.membershipGroups) {
        const activeMembership = group.userMemberships.find(
          (userMembership) => userMembership.status === 'active'
        );

        if (activeMembership) {
          hasMembership = true
        }
      }
    }
    if ((currentStepId === 'duration-host' || currentStepId.startsWith('duration-guest')) && !hasMembership && userProfile) return true
    if (currentStepId === 'pressure-host' || currentStepId === 'massage-host' || currentStepId.startsWith('massage-guest')) return true
    return false
  }

  const isContinueDisabledForStep = (): boolean => {
    const hasFormErrors = Object.values(optionsErrorStates).some((hasError) => hasError);
    if (currentStepId === 'guests' && hasFormErrors) {
      return true;
    }

    if (!isValid || hasPregnancyIssue) {
      return true;
    }

    if (currentStepId === 'duration-host' && !userSelections?.duration) {
      return true;
    }
    if (currentStepId.startsWith('duration-guest')) {
      const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
      if (!guestsSelections || !guestsSelections[guestIndex]?.duration) {
        return true;
      }
    }

    if (currentStepId === 'pressure-host' && !userSelections?.pressure) {
      return true;
    }
    if (currentStepId.startsWith('pressure-guest')) {
      const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
      if (!guestsSelections || !guestsSelections[guestIndex]?.pressure) {
        return true;
      }
    }

    if (currentStepId === 'massage-host' && !userSelections?.massage) {
      return true;
    }
    if (currentStepId.startsWith('massage-guest')) {
      const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
      if (!guestsSelections || !guestsSelections[guestIndex]?.massage) {
        return true;
      }
    }
    if (currentStepId === 'appointment' && !checkoutLink) {
      return true;
    }

    return false;
  };

  const handleSubmitEnhancements = (skip: boolean) => {
    if (skip) {
      // Restore only mandatory enhancements
      setUserSelections((prev) => ({
        ...prev,
        enhancements: initialEnhancements.host || [],
      }));

      setGuestsSelections((prev) =>
        prev?.map((guestSelection, index) => ({
          ...guestSelection,
          enhancements: initialEnhancements.guests[index] || [],
        })) || []
      );
      closeEnhancementsModal();
      triggerSubmit();
    } else {
      setSkipEnhancements(false);
      setCheckoutLink('');
      setIsLinkLoading(true);
      setAddedEnhancementsOnCheckout(true)
    }
  };

  useEffect(() => {
    if (addedEnhancementsOnCheckout) {
      triggerSubmit();
    }
  }, [addedEnhancementsOnCheckout])

  const handleScrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
 
      const headerHeight = globalStyles.mainMenuHeightSM + globalStyles.subMenuHeightSM;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleScrollToSection(currentStepId)
  }, [currentStepId])

  const areEnhancementsSelected = () => {
    const isMandatory = (enhancement: Service) => {
      return (
        enhancement.restrictions.some(
          (r) => r.type === 'pregnancy_required'
        ) && userOptions?.pregnant
      );
    };

    const hostEnhancementsSelected =
      userSelections?.enhancements &&
      userSelections.enhancements.some((enhancement) => !isMandatory(enhancement));

    const guestEnhancementsSelected =
      guestsSelections &&
      guestsSelections.some((guestSelection) =>
        guestSelection?.enhancements?.some((enhancement) => !isMandatory(enhancement))
      );

    return hostEnhancementsSelected || guestEnhancementsSelected;
  };

  const isSubmissionDisabled = isContinueDisabledForStep()
  if (isLoadingUserProfile || isLoadingBoutique || isLoadingServices || isLoadingMemberships) return <Spinner />
  if (selectedBoutique && servicesError) return <ErrorScreen errors={[servicesError]} />


  const stepsContainer = () => {
    return (
      <div className={styles.formSteps} id="steps-container">
        {currentStepId === 'duration-host' && services &&
          <BookingFlowDuration
            durations={services.durations}
            services={services.services}
            onSelectDuration={onDurationSelect}
            nextStep={goToNextStep}
            groupAppointment={guestsInfo && guestsInfo?.length > 0 || undefined}
            userMemberships={userMemberships}
            isLoadingMemberships={isLoadingMemberships}
            prevSelectedDuration={userSelections?.duration}
            isPregnant={!!userOptions?.pregnant}
            isMinor={!!userOptions?.gender}
            saveDataToSessionStorage={saveDataToSessionStorage}
            promotion={promo}
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `duration-guest-${index + 1}` && services &&
          <BookingFlowDuration
            key={index}
            durations={services.durations}
            services={services.services}
            onSelectDuration={onDurationSelect}
            nextStep={goToNextStep}
            groupAppointment
            userMemberships={userMemberships}
            isLoadingMemberships={isLoadingMemberships}
            prevSelectedDuration={(guestsSelections && guestsSelections[index]?.duration) || undefined}
            isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
            isMinor={(guestsInfo && !!guestsInfo[index]?.gender) || undefined}
            isGuest={true}
            promotion={promo}
          />
        ))}
        {currentStepId === 'pressure-host' &&
          <BookingFlowPressure
            onSelectPressure={(pressure) => {
              setUserSelections((prev) => ({ ...prev, pressure }))
              triggerSubmit();
            }}
            prevSelectedPressure={userSelections?.pressure}
            isPregnant={!!userOptions?.pregnant}
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `pressure-guest-${index + 1}` &&
          <BookingFlowPressure
            key={index}
            onSelectPressure={(pressure) => {
              const updatedSelections = guestsSelections ? [...guestsSelections] : [];
              updatedSelections[index] = { ...updatedSelections[index], pressure };
              setGuestsSelections(updatedSelections);
              triggerSubmit();
            }}
            prevSelectedPressure={(guestsSelections && guestsSelections[index]?.pressure) || undefined}
            isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
          />
        ))}
        {currentStepId === 'massage-host' &&
          <BookingFlowMassage
            onSelectMassage={(massage) => {
              setUserSelections((prev) => ({ ...prev, massage }))
              triggerSubmit();
            }}
            services={services?.services || []}
            duration={userSelections?.duration || 0}
            prevSelectedMassage={userSelections?.massage}
            isPregnant={!!userOptions?.pregnant}
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `massage-guest-${index + 1}` &&
          <BookingFlowMassage
            key={index}
            onSelectMassage={(massage) => {
              const updatedSelections = guestsSelections ? [...guestsSelections] : [];
              updatedSelections[index] = { ...updatedSelections[index], massage };
              setGuestsSelections(updatedSelections);
              triggerSubmit();
            }}
            services={services?.services || []}
            duration={(guestsSelections && guestsSelections[index].duration) || 0}
            prevSelectedMassage={(guestsSelections && guestsSelections[index]?.massage) || undefined}
            isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
          />
        ))}
        {currentStepId === 'enhancements-host' &&
          <BookingFlowEnhancements
            onSelectEnhancements={(enhancements) => {
              setUserSelections((prev) => ({ ...prev, enhancements }))
            }}
            services={services?.services || []}
            selectedMassage={userSelections?.massage}
            prevSelectedEnhancements={userSelections?.enhancements}
            isPregnant={!!userOptions?.pregnant}
            isDeepPressure={userSelections?.pressure === 'deep'}
            isGroupBooking={guestsInfo && guestsInfo.length > 0}

          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `enhancements-guest-${index + 1}` &&
          <BookingFlowEnhancements
            key={index}
            onSelectEnhancements={(enhancements) => {
              const updatedSelections = guestsSelections ? [...guestsSelections] : [];
              updatedSelections[index] = { ...updatedSelections[index], enhancements };
              setGuestsSelections(updatedSelections);
            }}
            services={services?.services || []}
            selectedMassage={guestsSelections && guestsSelections[index].massage || undefined}
            prevSelectedEnhancements={(guestsSelections && guestsSelections[index]?.enhancements) || undefined}
            isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
            isDeepPressure={(guestsSelections && guestsSelections[index]?.pressure === 'deep') || undefined}
            isGroupBooking={guestsInfo && guestsInfo.length > 0}
          />
        ))}
        {currentStepId === 'appointment' && (
          <BookingFlowAppointment
            boutiqueId={selectedBoutique?.id || boutiqueId}
            boutiquePhone={selectedBoutique?.phoneNumber || ''}
            userSelections={userSelections || {}}
            userOptions={userOptions || {}}
            guestsSelections={guestsSelections || []}
            guestsInfo={guestsInfo || []}
            therapists={therapists?.therapists || []}
            isLoadingTherapists={isLoadingTherapists}
            hostZenotiId={userProfile?.data.zenotiId || ''}
            setCheckoutLink={setCheckoutLink}
            checkoutLink={checkoutLink}
            setGuestsSelections={setGuestsSelections}
            setUserSelections={setUserSelections}
            preselectedTimeSlot={preselectedTime && preselectedTime !== 'undefined' ? preselectedTime : ''}
            addedExtraEnhancements={addedEnhancementsOnCheckout}
            setBoutiqueWithinRadius={setBoutiqueWithinRadius}
            setShowLocationSearch={setShowLocationSearch}
          />
        )}

      </div>
    )
  }

  return (
    <div className={styles.booking}>
      <div className={styles.stepper} id="bookingStepperMobile">
        {selectedBoutique && (
          <div onClick={() => setShowLocationSearch(true)} className={`${styles.boutique}`}>
            <div className={styles.boutiqueTitle}>
              <img src={MapPinIcon} alt="map pin icon" />
              {selectedBoutique.name}
            </div>
            <p className={styles.address}>
              {selectedBoutique.address}{selectedBoutique.address2 && ` ${selectedBoutique.address2}`}, {selectedBoutique.city}, {selectedBoutique.state} {selectedBoutique.zip}
            </p>
          </div>
        )}

        <div
          className={`${styles.step} ${currentStepId === 'guests' ? styles.active : ''} ${isStepClickable('guests') ? styles.highlighted : ''} ${styles.whoIsComing}`}
          onClick={() => handleStepClick('guests')}
        >
          <div className={`${styles.stepTitle}`} id="guests">
            <p>Who's Coming</p>
            {guestsInfo.length > 0 ? <p>Me, {guestsInfo.length} {guestsInfo.length === 1 ? "Guest" : "Guests"}</p> : <p>Just Me</p>}
          </div>
          {currentStepId === 'guests' && services?.bookingOptions &&
            <div className={styles.formSteps} id="steps-container">
              <BookingFlowGuests
                guestsInfo={guestsInfo}
                setGuestsInfo={setGuestsInfo}
                setGuestsSelections={setGuestsSelections}
                removeGuest={removeGuest}
                activeGuestIndex={activeGuestIndex}
                setActiveGuestIndex={setActiveGuestIndex}
                bookingOptions={services?.bookingOptions}
                handlePregnancySelection={handlePregnancySelection}
                control={control}
                errors={errors}
                clearErrorsForGuest={clearErrorsForGuest}
                setErrorStates={setOptionsErrorStates}
              />
            </div>
          }
        </div>

        {!hasGuests ? (
          stepGroups.map((group) => (
            <div
              key={group.groupLabel}
              className={`${styles.group} ${currentStepId === group.steps[0].id ? styles.active : ''}`}
              onClick={() => handleStepClick(group.steps[0].id)}
              id={group.steps[0].id}
            >
              <div className={`${styles.step} ${isStepClickable(group.steps[0].id) ? styles.highlighted : ''}`}>
                <div className={`${styles.stepTitle}`} id={group.steps[0].id}>
                  {group.groupLabel}
                  {/* Display the step value if available */}
                  {getStepValue(group.steps[0].id) && <span className={styles.stepValue}>{getStepValue(group.steps[0].id)}</span>}
                </div>
                {currentStepId === group.steps[0].id && stepsContainer()}
              </div>
            </div>
          ))
        ) : (
          stepGroups.map((group, groupIndex) => {
            const isGroupCompleted = group.steps.some(step => completedSteps.includes(step.id));
            const isCurrentGroup = group.steps.some(step => step.id === currentStepId);

            return (
              <div key={groupIndex} className={`${styles.group} ${isCurrentGroup ? styles.active : ''}`}>
                <div
                  className={`${styles.groupTitle} ${isGroupCompleted ? styles.highlighted : ''}`}
                  onClick={() => handleGroupClick(groupIndex)}
                >
                  {group.groupLabel}
                </div>

                {(expandedGroupIndex === groupIndex || isCurrentGroup) && (
                  <div className={styles.groupSteps}>
                    {group.steps.map((step, stepIndex) => (
                      <Fragment key={step.id}>
                        <div
                          key={stepIndex}
                          id={step.id}
                          className={`${styles.step} ${step.id === currentStepId ? styles.active : ''} ${isStepClickable(step.id) ? styles.highlighted : ''}`}
                          onClick={() => handleStepClick(step.id)}
                        >
                          {step.label}
                          {/* Display the step value if available */}
                          {getStepValue(step.id) && <span className={styles.stepValue}>{getStepValue(step.id)}</span>}

                        </div>
                        {step.id === currentStepId && stepsContainer()}
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        )}
        {preselectedDateTimeLabel ? (
          <div
            className={`${styles.step} ${currentStepId === 'appointment' ? styles.active : ''} ${isStepClickable('appointment') ? styles.highlighted : ''}`}
            onClick={() => handleStepClick('appointment')}
          >
            <div className={`${styles.stepTitle}`} id="appointment">
              Select Appointment
              <span className={styles.stepValue}>{preselectedDateTimeLabel}</span>
            </div>
            {currentStepId === 'appointment' && stepsContainer()}
          </div>
        ) : (
          <div
            className={`${styles.step} ${currentStepId === 'appointment' ? styles.active : ''}`}
            onClick={() => handleStepClick('appointment')}
          >
            <div className={`${styles.stepTitle}`} id="appointment">
              Select Appointment
            </div>
            {currentStepId === 'appointment' && stepsContainer()}
          </div>
        )}
        {showLocationSearch && <LocationSearch currentBoutique={selectedBoutique || null} setBoutique={setSelectedBoutique} onClose={() => setShowLocationSearch(false)} />}
      </div>
      {!hideContinueButton() &&
        <div className={styles.controls}>
          <div className={`${styles.controlsContainer} container`}>
            <div className={styles.nextStep}>Next: {getNextStepLabel()}</div>
            <div className={styles.controlButtons}>
              {guestsInfo.length > 0 && currentStepId === 'guests' && <button onClick={addGuest} className={`button inverted ${styles.controlButton}`}>Add Another Guest</button>}
              <button onClick={triggerSubmit} disabled={isSubmissionDisabled} className={`button ${styles.controlButton} ${isSubmissionDisabled ? "disabled" : ""}`}>{currentStepId === 'appointment' ? 'Proceed to checkout' : 'Continue'}</button>
            </div>
          </div>
        </div>
      }
      <Modal
        isOpen={isPregnancyModalOpen}
        onClose={() => setIsPregnancyModalOpen(false)}
        hideCloseButton
      >
        <div className={styles.pregnancyModalContent}>
          <h2 className={styles.pregnancyModalTitle}>
            We&apos;re sorry, this service is not recommended for guests in their first trimester of pregnancy.
          </h2>
          <p>This service is not recommended for guests in their first trimester of pregnancy.</p>
          {/* <div className={styles.pregnancyModalContacts}>
            <div className={styles.contactsRow}>
              <img src={MapPinIcon} alt="map pin icon" />
              <p className={styles.boutiqueName}>{selectedBoutique?.name}</p>
            </div>
            <div className={styles.contactsRow}>
              <img src={PhoneIcon} alt="phone icon" />
              <p>{formatPhoneNumber(selectedBoutique?.phoneNumber || '')}</p>
            </div>
          </div> */}
          <button className={`button inverted w-full`} onClick={() => setIsPregnancyModalOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showLeaveModal}
        onClose={() => setShowLeaveModal(false)}
        hideCloseButton
      >
        <div className={styles.leaveBookingModal}>
          <h2>Are you sure you want to leave this booking?</h2>
          <p>Are you sure you want to leave this booking?</p>
          <div>
            <button className="button">Save and leave</button>
            <button>Leave without saving</button>
          </div>
          <button className="button__underline">Cancel</button>
        </div>
      </Modal>
      <Modal
        isOpen={showRestoreDataModal}
        onClose={() => setShowRestoreDataModal(false)}
        hideCloseButton
      >
        <div className={styles.restoreDataBookingModal}>
          <h2>Would you like to pick up where you left off?</h2>
          <p>We found saved data from your last booking attempt. Would you like to restore it and continue where you left off?</p>
          <button onClick={() => {
            loadDataFromSessionStorage()
            setShowRestoreDataModal(false)
          }} className="button">Continue</button>
          <button onClick={() => {
            clearBookingDataFromSessionStorage()
            setShowRestoreDataModal(false)
          }} className="button__underline">START OVER</button>
        </div>
      </Modal>
      <Modal isOpen={isHubspotModalOpen} onClose={handleModalClose}>
        <div className={styles.hubspotModalContent}>
          <h2>You have selected the next available appointment.</h2>
          <ul>
            <li><img src={CalendarIcon} alt="calendar" />{preselectedTimeLabel}</li>
            <li><img src={ClockIcon} alt="clock" />{preselectedDateTimeLabel}</li>
            <li><img src={MapPinIcon} alt="map pin" />{selectedBoutique?.name}</li>
            <li><img src={MassageIcon} alt="insignia" />{preselectedService?.name}</li>
          </ul>
          <p>Please choose your desired duration, pressure preference, massage type and enhancements.</p>
          <button className="button" onClick={handleModalClose}>Continue</button>
        </div>
      </Modal>
      {showLocationSearch && (<LocationSearch currentBoutique={selectedBoutique || null} setBoutique={setSelectedBoutique} onClose={() => setShowLocationSearch(false)} preselectedBoutique={boutiqueWithinRadius} />)}
      {showEnhancementsPopUp && (
        <div className={styles.enhancementsPopUpOverlay}>
          <div className={styles.enhancementsPopUp}>
            <div className={styles.popUpHeaderContainer}>
              <div className={`${styles.enhancementsPopUpHeader} container`}>
                <h3>Would you like to add enhancements?</h3>
                <button onClick={closeEnhancementsModal} className={styles.closeButton}>&#x2715;</button>
              </div>
            </div>
            <div className={`${styles.enhancementsPopUpContent} container`}>
              {guestsInfo.length > 0 && (
                <h3>For Myself</h3>
              )}
              <BookingFlowEnhancements
                onSelectEnhancements={(enhancements) => {
                  setUserSelections((prev) => ({ ...prev, enhancements }))
                }}
                services={
                  services?.services.filter(s =>
                    !s.restrictions.some(r => r.type === 'pregnancy_required') || userOptions?.pregnant
                  ) || []
                }
                // services={
                //   services?.services.filter(s => {
                //     const isPregnancyRequired = s.restrictions.some(r => r.type === 'pregnancy_required');
                //     const isOfferedByTherapist = !userSelections?.therapist || 
                //       userSelections.therapist.offeredServicesZenotiIds.includes(s.zenotiId);

                //     return (!isPregnancyRequired || userOptions?.pregnant) && isOfferedByTherapist;
                //   }) || []
                // }
                selectedMassage={userSelections?.massage}
                prevSelectedEnhancements={userSelections?.enhancements}
                isPregnant={!!userOptions?.pregnant}
                isDeepPressure={userSelections?.pressure === 'deep'}
                isGroupBooking={guestsInfo && guestsInfo.length > 0}
                className={styles.enhancementsPopUpGroup}
              />
              {guestsInfo.map((guest, index) => (
                <div key={guest.email + index}>
                  <h3>For {guest.firstName}</h3>
                  <BookingFlowEnhancements
                    onSelectEnhancements={(enhancements) => {
                      const updatedSelections = guestsSelections ? [...guestsSelections] : [];
                      updatedSelections[index] = { ...updatedSelections[index], enhancements };
                      setGuestsSelections(updatedSelections);
                    }}
                    // services={
                    //   services?.services.filter(s => {
                    //     const isPregnancyRequired = s.restrictions.some(r => r.type === 'pregnancy_required');
                    //     const isOfferedByTherapist = !guestsSelections?.[index]?.therapist || 
                    //       guestsSelections?.[index]?.therapist?.offeredServicesZenotiIds.includes(s.zenotiId);

                    //     return (!isPregnancyRequired || (guestsInfo && !!guestsInfo[index]?.pregnant)) && isOfferedByTherapist;
                    //   }) || []
                    // }
                    services={
                      services?.services.filter(s =>
                        !s.restrictions.some(r => r.type === 'pregnancy_required') || (guestsInfo && !!guestsInfo[index]?.pregnant)
                      ) || []
                    }
                    selectedMassage={guestsSelections && guestsSelections[index].massage || undefined}
                    prevSelectedEnhancements={(guestsSelections && guestsSelections[index]?.enhancements) || undefined}
                    isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
                    isDeepPressure={(guestsSelections && guestsSelections[index]?.pressure === 'deep') || undefined}
                    isGroupBooking={guestsInfo && guestsInfo.length > 0}
                    className={styles.enhancementsPopUpGroup}
                  />
                </div>
              ))}
            </div>
            <div className={styles.popUpActionsContainer}>
              <div className={`${styles.popUpActions} container`}>
                <button
                  onClick={() => handleSubmitEnhancements(true)}
                  className="button inverted w-full"
                >
                  Skip
                </button>
                <button
                  onClick={() => handleSubmitEnhancements(false)}
                  disabled={!areEnhancementsSelected()}
                  className={`button w-full ${!areEnhancementsSelected() ? 'disabled' : ''}`}
                >
                  {isLinkLoading ? <Spinner size={20} /> : 'Add'}
                </button>
              </div>
            </div>

          </div>
        </div>
      )}

    </div>
  )
}


export default BookingMobile