import { get, put, del } from './apiClient';
import { MembershipByIdResponse, MembershipResponse, MembershipTermsResponse } from '../types/memberships';
import { GenericSuccessResponse } from '../types/api';

export const fetchMemberships = async (
  boutiqueId?: string,
): Promise<MembershipResponse> => {

  const response = await get<MembershipResponse>(`/api/v1/boutiques/${boutiqueId}/memberships`);
  return response.data;
};

export const fetchMembershipById = async (id: string, boutiqueSlug: string): Promise<MembershipByIdResponse> => {
  const response = await get<MembershipByIdResponse>(`/api/v1/boutiques/${boutiqueSlug}/memberships/${id}`);
  
  return response.data;
};

export const freezeMembership = async (membershipId: string, boutiqueId: string): Promise<GenericSuccessResponse> => {
  const response = await put<GenericSuccessResponse, {boutiqueId: string}>(`/api/v1/user/memberships/${membershipId}/freeze`, {boutiqueId});
  
  return response.data;
}

export const unfreezeMembership = async (membershipId: string, boutiqueId: string): Promise<GenericSuccessResponse> => {
  const response = await put<GenericSuccessResponse, {boutiqueId: string}>(`/api/v1/user/memberships/${membershipId}/unfreeze`, {boutiqueId});
  
  return response.data;
}

// this endpoint cancels the membership immediately, use only for upgrade membership flow
export const cancelMembership = async (id: string): Promise<GenericSuccessResponse> => {
  const response = await del<GenericSuccessResponse, null>(`/api/v1/user/memberships/${id}`);
  
  return response.data;
};

export const getMembershipTerms = async (membershipId: string, boutiqueId: string): Promise<MembershipTermsResponse> => {
  const response = await get<MembershipTermsResponse>(`/api/v1/boutiques/${boutiqueId}/purchase_agreements?membership_zenoti_id=${membershipId}`);

  return response.data;
}