import styles from '../styles/components/BookingFlowMassage.module.scss'
import ArrowExpandIcon from '../assets/icons/arrow-expand.svg'
import { Service } from '../types/bookings'
import { useState } from 'react';
import { useToast } from './core/ToastManager';

interface Props {
  services: Service[]
  duration: number;
  onSelectMassage: (service: Service) => void;
  prevSelectedMassage?: Service
  isPregnant?: boolean
}

export const BookingFlowMassage:React.FC<Props> = ({
  services,
  duration,
  onSelectMassage,
  prevSelectedMassage,
  isPregnant
}) => {
  const [showMassageBenefits, setShowMassageBenefits] = useState<string | number>('')
  const [selectedMassage, setSelectedMassage] = useState<Service | undefined | null>(prevSelectedMassage)
  const filteredServices = services.filter((service) => {
    return service.duration - service.recoveryTime === duration
  })

  const { addToast } = useToast()

  const handleSelectMassage = (massage: Service) => {
    if (isPregnant && massage.restrictions.find(m => m.type === 'pregnancy_excluded')) {
      addToast(`${massage.name} isn’t an option if you are pregnant.`)
    } else {
      setSelectedMassage(massage)
      onSelectMassage(massage)
    }
  }

  const handleShowBenefits = (event: React.MouseEvent, serviceId: string | number) => {
    event.stopPropagation()
    setShowMassageBenefits(serviceId === showMassageBenefits ? '' : serviceId)
  }
  return (
    <div className={styles.selectMassage}>
      {filteredServices.map((service) => {
        const isDisabled = isPregnant && service.restrictions.find(m => m.type === 'pregnancy_excluded')
        return (
          <div 
            className={`${styles.massage} ${selectedMassage?.id === service.id ? styles.selected : ''} ${isDisabled ? styles.disabled : ''}`}
            key={service.id}
            onClick={() => handleSelectMassage(service)}
          >
            <p className={styles.title}>{service.name}</p>
            <p className={`${styles.description} ${showMassageBenefits === service.id ? styles.showFullDescription : ''}`}>{service.description}</p>
            {service.meta.benefits && (
              <>
                {showMassageBenefits === service.id && (
                  <div>
                    <p className={styles.benefitsTitle}>Benefits:</p>
                    <p className={styles.benefitsDetails}>{service.meta.benefits}</p>
                  </div>
                )}
                <button className={`${styles.arrowButton} ${showMassageBenefits === service.id ? styles.active : ""}`} onClick={(e) => handleShowBenefits(e, service.id)}>
                  <img src={ArrowExpandIcon} alt="expand info" />
                </button>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}
