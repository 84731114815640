import React, { useState, useEffect, ReactNode, useRef } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { Boutique } from '../types/boutiques';
import styles from '../styles/components/LocationSearch.module.scss';
import { useBoutiques } from '../hooks/useBoutiques';
import MapPinFilledIcon from '../assets/icons/map-pin-filled.svg'
import ActiveMapPinIcon from '../assets/icons/active-map-pin.svg'
import GeoIcon from '../assets/icons/geo.svg'
import CrossIcon from '../assets/icons/cross.svg'
import MapIcon from '../assets/icons/map.svg'
import PhoneIcon from '../assets/icons/phone.svg'
import ClockIcon from '../assets/icons/clock.svg'
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import 'mapbox-gl/dist/mapbox-gl.css';
import { DateTime } from 'luxon';
import { useToast } from './core/ToastManager';
import { formatPhoneNumber } from '../utils/formatPhone';
import { getDistance } from '../utils/getDistance';

interface Props {
  currentBoutique: Boutique | null;
  selectLocationButtonCopy?: string;
  setBoutique: (boutique: Boutique) => void;
  onClose: () => void;
  preselectedBoutique?: Boutique | null;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_TOKEN;

export const LocationSearch: React.FC<Props> = ({
  currentBoutique,
  setBoutique,
  onClose,
  preselectedBoutique,
  selectLocationButtonCopy = "Book"
}) => {
  const { addToast } = useToast();
  const { data: boutiquesData } = useBoutiques({page: 1, perPage: 100});
  const boutiques = boutiquesData?.data.boutiques || []
  const defaultCoordinates = preselectedBoutique
    ? { latitude: preselectedBoutique.lat, longitude: preselectedBoutique.lng }
    : currentBoutique
    ? { latitude: currentBoutique.lat, longitude: currentBoutique.lng }
    : { latitude: 34.0522, longitude: -118.2437 }; // Default fallback coordinates
  
  const [viewport, setViewport] = useState({
    ...defaultCoordinates,
    zoom: 10,
  });
  
  useEffect(() => {
    if (preselectedBoutique &&
      (viewport.latitude !== preselectedBoutique.lat ||
      viewport.longitude !== preselectedBoutique.lng)
    ) {
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude: preselectedBoutique.lat,
        longitude: preselectedBoutique.lng,
      }));
    }
    if (preselectedBoutique) {
      addToast(
        'Services, pricing, and promotional offers may vary by boutique. Gift Cards can only be used at boutiques where they were purchased.',
        'info',
        4500
      );
    }
  }, [preselectedBoutique]);



  const isCoordinatesValid = (lat: number, lng: number) => {
    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  }

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBoutiques, setFilteredBoutiques] = useState<Boutique[]>(boutiques);
  const [selectedBoutique, setSelectedBoutique] = useState<Boutique | null>(preselectedBoutique || null)

  const basicLocationFilter = (lowerCaseQuery: string) => {
    return boutiques.filter(
      (b) =>
        b.name.toLowerCase().includes(lowerCaseQuery) ||
        b.city?.toLowerCase().includes(lowerCaseQuery) ||
        b.state?.toLowerCase().includes(lowerCaseQuery) ||
        b.zip?.toLowerCase().includes(lowerCaseQuery) ||
        b.address?.toLowerCase().includes(lowerCaseQuery)
    )
  }

  // Update this section of code to use react hooks
  const geocodeAddress = async (query: string) => {
  
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}`;
  
    try {
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error in geocodeAddress:", error);
      throw error;
    }
  };

  const sortedLocations = (allLocations: Boutique[], searchLoc: { center: any[]; bbox: any[]; text: string; } | null) => {
    const locations = [...allLocations]
    if (locations.length === 0) {
      return []
    }

    if (!searchLoc) {
      return locations
    }

    const s = () => {
      let lat = searchLoc.center[1]
      let lng = searchLoc.center[0]

      if (searchLoc.bbox) {
        const lat1 = searchLoc.bbox[1]
        const lat2 = searchLoc.bbox[3]
        const lng1 = searchLoc.bbox[0]
        const lng2 = searchLoc.bbox[2]

        lat = (lat1 + lat2) / 2
        lng = (lng1 + lng2) / 2
      }

      return { lat, lng }
    }

    const locs = locations
      .sort((a, b) => {
        const distA = getDistance(s(), a)
        const distB = getDistance(s(), b)
        return distA - distB
      })

    if (searchLoc) {
      const isNum = /^\d+$/.test(searchLoc.text)
      if (isNum) {
        const searchZip = locs.findIndex(arr => arr.zip === searchLoc.text)

        if (searchZip !== -1 && locs[0] !== locs[searchZip]) {
          const element = locs[searchZip]
          locs.splice(searchZip, 1)
          locs.splice(0, 0, element)
        }
      }
      return locs
    }
  }
  
  const handleSearchFunctionality = async () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const basicFilterResult = basicLocationFilter(lowerCaseQuery)
    
    if(basicFilterResult.length > 0) {
      setFilteredBoutiques(basicFilterResult)
    }
    else {
      const searchQueryGeoLocation = await geocodeAddress(lowerCaseQuery)
      const sortedResult = sortedLocations(boutiques, searchQueryGeoLocation.features[0])
      
      if(sortedResult) {
        setFilteredBoutiques(sortedResult)
      }
    }
  }

  // Update filtered boutiques based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredBoutiques(boutiques);
    } else {
      handleSearchFunctionality()
    }
    if (boutiques.length > 0 && !preselectedBoutique) {
      setViewport({
        latitude: boutiques[0].lat, 
        longitude: boutiques[0].lng,
        zoom: 10,
      })
    }
  }, [searchQuery, boutiques]);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleViewOnMap = (boutique: Boutique) => {
    if (!isCoordinatesValid(boutique.lat, boutique.lng)) {
      addToast('Invalid coordinates for this boutique. We apologize for inconvenience', 'error');
      return
    }
    setViewport({
      latitude: boutique.lat, 
      longitude: boutique.lng,
      zoom: 10,
    })
    setSelectedBoutique(boutique)
  }

  // Render boutique markers on the map
  const renderMarkers = () => {
    return filteredBoutiques.map((boutique) => {
      if (!isCoordinatesValid(boutique.lat, boutique.lng)) {
        return null;
      }
  
      const isActive = selectedBoutique?.id === boutique.id;
  
      return (
        <Marker key={boutique.id} latitude={boutique.lat} longitude={boutique.lng}>
          <div onClick={() => handleViewOnMap(boutique)} className={styles.marker}>
            <img
              src={isActive ? ActiveMapPinIcon : MapPinFilledIcon}
              alt={isActive ? 'active map pin' : 'map pin'}
              className={isActive ? styles.activePin : ''}
            />
          </div>
        </Marker>
      );
    });
  };

  const getOpenStatus = (hours: Record<string, string[][]>): string | ReactNode => {
    const now = DateTime.local().setZone('America/New_York'); // Assuming boutique timezone is EST
    const dayOfWeek = now.toFormat('cccc').toLowerCase();
    const todayHours = hours[dayOfWeek];

    if (todayHours) {
      const [openTime, closeTime] = todayHours[0];
      const openDateTime = DateTime.fromFormat(openTime, 'HH:mm', { zone: 'America/New_York' });
      const closeDateTime = DateTime.fromFormat(closeTime, 'HH:mm', { zone: 'America/New_York' });

      if (now >= openDateTime && now <= closeDateTime) {
        return (
          <>
            <span>Open Now</span> - Closes at {closeDateTime.toFormat('h:mm a')}
          </>
        );
      }
    }
    // Find next opening time
    const tomorrow = now.plus({ days: 1 }).toFormat('cccc').toLowerCase();
    const nextDayHours = hours[tomorrow];
    if (nextDayHours) {
      const [nextOpenTime] = nextDayHours[0];
      const nextOpenDateTime = DateTime.fromFormat(nextOpenTime, 'HH:mm', { zone: 'America/New_York' });
      return (
        <>
          <span>Closed Now</span> - Opens at {nextOpenDateTime.toFormat('h:mm a')}
        </>
      );
    }

    return 'Closed Now';
  };

  const formatWeeklyHours = (hours: string | Record<string, string[][]> | undefined): string[] | React.ReactNode[] => {
    if (!hours) return ['Hours not available'];
  
    let hoursData: Record<string, string[][]>;
    
    // If hours is a JSON string, parse it to an object
    if (typeof hours === 'string') {
      try {
        hoursData = JSON.parse(hours);
      } catch (error) {
        return ['Invalid hours format'];
      }
    } else {
      hoursData = hours;
    }
  
    // Convert the hours object into the desired format
    return Object.entries(hoursData).map(([day, timeRanges]) => {
      const formattedDay = day.charAt(0).toUpperCase() + day.slice(1); // Capitalize the day
      if (!timeRanges || timeRanges.length === 0) {
        return `${formattedDay}: Closed`;
      }
  
      const formattedHours = timeRanges
        .map(([open, close]) => {
          const openTime = DateTime.fromFormat(open, 'HH:mm').toFormat('h:mm a');
          const closeTime = DateTime.fromFormat(close, 'HH:mm').toFormat('h:mm a');
          return `${openTime} - ${closeTime}`;
        })
        .join(', ');
  
      return <div className={styles.workHours} key={formattedDay}><span>{formattedDay}</span> {formattedHours}</div>;
    });
  };

  const handleBoutiqueSelect = (boutique: Boutique) => {
    if (boutique.useOldSite) {
      window.open(boutique.oldBookingUrl,'_blank')
    } else {
      setBoutique(boutique);
      onClose();
    }
  }

  const boutiqueListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedBoutique && boutiqueListRef.current) {
      boutiqueListRef.current.scrollTop = 0;
    }
  }, [selectedBoutique]);


  return (
    <div className={styles.locationSearch}>
      <div className={styles.mapContainer}>
        <ReactMapGL
          {...viewport}
          style={{width: '100%', height: '100%'}}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewport(evt.viewState)}
        >
          {renderMarkers()}
        </ReactMapGL>
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          className={styles.searchBox}
          placeholder="Search by city, state, zip, or address"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className={styles.inputButtons}>
          <button onClick={() => setSearchQuery('')} className={styles.clearButton}><img src={CrossIcon} alt="Clear search" /></button>
          {/* <button className={styles.geoButton}><img src={GeoIcon} alt="Geo location" /></button> */}
        </div>
      </div>
      <div ref={boutiqueListRef} className={`${styles.boutiqueList} ${filteredBoutiques.length === 0 ? styles.noResults : ""}`}>
        {selectedBoutique ? (
          <div className={styles.boutiqueInfo}>
            <button onClick={()=> setSelectedBoutique(null)} className={styles.closeInfoButton}><img src={CrossIcon} alt="close boutique info" /></button>
            <h3>{selectedBoutique.name}</h3>
            <p>{selectedBoutique.address}{selectedBoutique.address2 && ` ${selectedBoutique.address2}`}, {selectedBoutique.city}, {selectedBoutique.state} {selectedBoutique.zip}</p>
            <button onClick={() => handleBoutiqueSelect(selectedBoutique)} className="button w-full">{selectLocationButtonCopy}</button>
            <div className={styles.phone}>
              <img src={PhoneIcon} alt="phone" />
              {formatPhoneNumber(selectedBoutique.phoneNumber || '')}
            </div>
            <div className={styles.separator}></div>
            <div className={styles.clock}>
              <img src={ClockIcon} alt="hours of operation" />
              {selectedBoutique.hoursOfOperation && getOpenStatus(selectedBoutique.hoursOfOperation)}
            </div>
            {selectedBoutique.hoursOfOperation && selectedBoutique.hoursOfOperation && (
            <ul className={styles.weeklyHours}>
              {formatWeeklyHours(selectedBoutique.hoursOfOperation).map((formattedHour, index) => (
                <li key={index}>{formattedHour}</li>
              ))}
              </ul>
            )}

          </div>
        ) : (
          <>
            <ul>
              {filteredBoutiques.map((boutique) => (
                <li key={boutique.id} className={styles.boutiqueItem}>
                  <div onClick={() => handleViewOnMap(boutique)} className={styles.boutiqueName}>
                    <h3>{boutique.name}</h3>
                    <img src={ArrowDownIcon} alt="arrow" />
                  </div>
                  <p>{boutique.address}{boutique.address2 && ` ${boutique.address2}`}, {boutique.city}, {boutique.state} {boutique.zip}</p>
                  <div className={styles.buttons}>
                    <button onClick={() => handleBoutiqueSelect(boutique)} className={`${styles.selectButton} button`}>{selectLocationButtonCopy}</button>
                    <button onClick={() => handleViewOnMap(boutique)} className={`${styles.viewMapButton} button__link`}><img src={MapIcon} alt="map icon" /> View on Map</button>
                  </div>
                </li>
              ))}
            </ul>
            {filteredBoutiques.length === 0 && (
              <div className={styles.noLocations}>
                <h3>No Locations Found</h3>
                <p>There are no boutiques matching this text.</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LocationSearch;