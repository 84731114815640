import styles from './../styles/pages/RescheduleAppointment.module.scss';
import MapPinIcon from '../assets/icons/map-pin.svg';
import { useConfirmServiceBooking, useServices, useTherapists, useUpdateServiceBooking } from '../hooks/useBookings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBoutique } from '../hooks/useBoutiques';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { pushToDataLayer } from '../utils/tracking';
import { GuestsTherapists } from '../components/BookingFlowAppointment';
import { useEffect, useState } from 'react';
import Spinner from '../components/core/Spinner';
import { BookingSlot } from '../types/bookings';
import { useToast } from '../components/core/ToastManager';
import { DateTime } from 'luxon';
import { formatPhoneNumber } from '../utils/formatPhone';
import PreferencesIcon from '../assets/icons/preferences.svg';
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import CustomSelectDropdown from '../components/core/CustomSelectDropdown';
import { globalStyles } from '../constants/styles';

export const genders = [
  { value: 'any', label: 'Any' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const RescheduleAppointment:React.FC= () => {
  const { addToast } = useToast()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const locationId = searchParams.get('location')
  const therapistId = searchParams.get('therapistId')
  const addOnsNumber = searchParams.get('addOns')
  const serviceName = searchParams.get('service')
  const duration = searchParams.get('duration')
  const numberOfGuests = Number(searchParams.get('guests') || 0)
  const preselectedTime = searchParams.get('time')
  const preselectedDate = new Date(searchParams.get('date') || '');
  const appointmentId = searchParams.get('appointmentId')
  const { data: boutiqueResponse, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(locationId || '')
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(locationId || '')
  const { data: therapists, isLoading: isLoadingTherapists, error: therapistsError } = useTherapists(locationId || '');
  const displayDuration = services?.durations.find(d => d.serviceDurationMinutes === Number(duration))?.displayDurationMinutes
  const boutique = boutiqueResponse?.data
  const preselectedTherapist = therapists?.therapists.find(therapist => therapist.zenotiId === therapistId)
  const [dateValue, onDateChange] = useState<Value>(new Date(preselectedDate));
  const [slots, setSlots] = useState<BookingSlot[]>([])
  const [expandedTherapistPreference, setExpandedTherapistPreference] = useState<string[]>([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(preselectedTime || '')
  const today = new Date();
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(today.getMonth() + 3);
  const guestsNumberArray = new Array(numberOfGuests - 1).fill(0).map((_, i) => i + 1)
  const initialGuestsTherapists: GuestsTherapists = {
    host: {
      id: 'host',
      therapist: preselectedTherapist || null,
      gender: 'any',
    },
  };

  guestsNumberArray?.forEach((__, index) => {
    initialGuestsTherapists[`guest-${index}`] = {
      id: `guest-${index}`,
      therapist: null,
      gender: 'any',
    };
  });
  const [guestsTherapists, setGuestsTherapists] = useState<GuestsTherapists>(initialGuestsTherapists);

  const {
    mutate: createServiceBooking,
    data: serviceBookingData,
    isPending: isCreateServiceBookingPending,
  } = useUpdateServiceBooking(
    (data) => {
      setSlots(data.slots);
    },
    (error) => {
      setSlots([]);
      addToast(error.errorMessages[0] || 'Failed to create booking', 'error');
    }
  );

  const {mutate: confirmBooking, isPending: isConfirmBookingPending, error: confirmBookingError} = useConfirmServiceBooking(
    (data) => {
      if (data.success) {
        addToast('Your appointment has been rescheduled.', 'success')
        navigate(`/appointments`)
      }
      if (data.error) {
        addToast(`Failed to reschedule appointment: ${data.error}`, 'error')
      }
    },
    (error) => {
      addToast(`Failed to reschedule appointment: ${error.errorMessages[0]}`, 'error');
    }
  );

  const handleTherapistChange = (guestId: string, therapistId: string | null) => {
    if (guestId === 'host') {
      const currentTherapist = guestsTherapists['host'].therapist?.zenotiId
      const newTherapist = currentTherapist === therapistId ? null : therapists?.therapists.find(t => t.zenotiId === therapistId) || null;
      setGuestsTherapists(prevState => ({
        ...prevState,
        host: {
          ...prevState['host'],
          therapist: newTherapist,
        },
      }));
    }
  };
  
  const handleCreateServiceBooking = () => {
    const payload = {
      date: dateValue instanceof Date ? dateValue.toISOString() : '',
      appointmentId: appointmentId || '',
      therapistId: guestsTherapists['host'].therapist?.zenotiId || '',
      therapistGender: guestsTherapists['host'].gender || 'any',
    }

    createServiceBooking({
      boutiqueId: locationId || '',
      data: payload
    });
  };

  useEffect(() => {
    if (dateValue instanceof Date) {
      handleCreateServiceBooking();
    }
  }, [dateValue, guestsTherapists])

  useEffect(() => {
    if (preselectedTherapist) {
      setGuestsTherapists(prevState => ({
        ...prevState,
        host: {
          ...prevState['host'],
          therapist: preselectedTherapist,
        },
    }));
    }
  }, [preselectedTherapist])

  const handleConfirmReschedule = () => {
    if (serviceBookingData?.zenotiBookingId) {
      confirmBooking({
        data: {
          bookingId: serviceBookingData?.zenotiBookingId,
          time: selectedTimeSlot || '',
        },
        boutiqueId: boutique?.id || ''
      })
    }
  }

  const toggleTherapistDetails = (id: string) => {
    setExpandedTherapistPreference((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const getFilteredTherapists = (guestId: string) => {
    const selectedGender = guestsTherapists[guestId]?.gender || 'any';
  
    return therapists?.therapists
      .filter((therapist) => {
        if (selectedGender === 'any' || selectedGender === 'other') return true;
        return therapist.gender.toLowerCase() === selectedGender.toLowerCase();
      })
      .map((therapist) => ({
        value: therapist.zenotiId,
        label: therapist.nickName,
      })) || [];
  };

    const handleScrollToSection = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
   
        const headerHeight = globalStyles.mainMenuHeightSM + globalStyles.subMenuHeightSM;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerHeight;
  
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    };
  
    useEffect(() => {
      handleScrollToSection('reschedule')
    }, [])

  const isSubmissionDisabled = !selectedTimeSlot || isCreateServiceBookingPending || isConfirmBookingPending;

  return (
    <div className={styles.rescheduleAppointment}>
      <div className={styles.stepperWrapper}>
        <h2>Appointment Details</h2>
        <div className={styles.stepper}>
          {boutique && (
            <div className={`${styles.boutique}`}>
              <div className={styles.boutiqueTitle}>
                <img src={MapPinIcon} alt="map pin icon" />
                {boutique.name}
              </div>
              <p className={styles.address}>
                {boutique.address}{boutique.address2 && ` ${boutique.address2}`}, {boutique.city}, {boutique.state} {boutique.zip}
              </p>
            </div>
          )}
          <div className={`${styles.step} ${styles.whoIsComing}`}>
            <p>Who’s Coming</p>
            {numberOfGuests > 1 ? <p>Me, {numberOfGuests - 1} {numberOfGuests === 2 ? "Guest" : "Guests"}</p> : <p>Just Me</p>}
          </div>
          <div className={`${styles.group}`}>
            <div className={`${styles.step}`}>
              Choose Duration
              <span className={styles.stepValue}>{displayDuration} Min</span>
            </div>
          </div>
          <div className={`${styles.group}`}>
            <div className={`${styles.step}`}>
              Select Massage
              <span className={styles.stepValue}>{serviceName}</span>
            </div>
          </div>
          <div className={`${styles.group}`}>
            <div className={`${styles.step}`}>
              Choose Enhancements
              <span className={styles.stepValue}>{Number(addOnsNumber) ? `${addOnsNumber} Selected` : 'None'}</span>
            </div>
          </div>
          <div
            className={`${styles.step} ${styles.active} ${styles.highlighted}`}
          >
            Select Appointment
            <span className={styles.stepValue}>{preselectedTime}</span>
          </div>
        </div>
      </div>

      <div className={styles.appointmentDetailsWrapper} id="reschedule">
        <h2>Select Appointment</h2>
        <div className={styles.appointment}>
          <div className={styles.leftCol}>
            <Calendar
              onChange={(value) => {
                onDateChange(value)
                pushToDataLayer({
                  event: 'dateTimeSelection',
                  workflowName: 'booking',
                  action: 'buttonClick',
                })
              }}
              value={dateValue}
              className={styles.calendar}
              tileClassName={styles.tile}
              formatShortWeekday={(locale, date) =>
                date.toLocaleDateString(locale, { weekday: 'narrow' })
              }
              formatMonth={(locale, date) =>
                date.toLocaleDateString(locale, { month: 'short' })
              }
              calendarType="gregory"
              minDate={today}
              maxDate={threeMonthsFromNow}
              next2Label={null}
              prev2Label={null}
            />
            <div className={styles.preferencesHeader}>
            <img src={PreferencesIcon} alt="preferences" />
            <p>Therapist Preferences</p>
          </div>
          {numberOfGuests && numberOfGuests > 0 && <div 
            onClick={(e) => {
              e.stopPropagation()
              toggleTherapistDetails('host')
            }}
            className={`${styles.expandingGuestBlock} ${expandedTherapistPreference.includes('host') ? styles.expanded : ''}`}
            >
            <p className={styles.guestName}>For You</p>
            <div className={styles.expandingContentRight}>
              <p>
              {
                guestsTherapists['host'].therapist
                  ? guestsTherapists['host'].therapist?.nickName
                  : guestsTherapists['host'].gender && guestsTherapists['host'].gender !== 'any'
                  ? `Any ${guestsTherapists['host'].gender.charAt(0).toUpperCase() + guestsTherapists['host'].gender.slice(1)} Therapist`
                  : 'Any Therapist'
              }
              </p>
              <img src={ArrowDownIcon} alt="Toggle therapist details" />
            </div>
          </div>}
          {(expandedTherapistPreference.includes('host') || numberOfGuests  === 0) && (
            <div>
              <CustomSelectDropdown
                label="Gender"
                onChange={(gender) => {
                  setGuestsTherapists(prevState => ({
                    ...prevState,
                    host: {
                      ...prevState['host'],
                      therapist: null,
                      gender,
                    },
                  }))
                  pushToDataLayer({
                    event: 'therapistGender',
                    workflowName: 'booking',
                    action: 'buttonClick',
                  })
                }
                }
                options={genders}
                selectedOption={guestsTherapists['host']?.gender || 'any'}
              />
              <CustomSelectDropdown
                label="Therapist"
                onChange={(therapistId) => {
                  handleTherapistChange('host', therapistId)
                  pushToDataLayer({
                    event: 'therapistSelection',
                    workflowName: 'booking',
                    action: 'buttonClick',
                  })
                }}
                options={getFilteredTherapists('host') || []}
                selectedOption={guestsTherapists['host']?.therapist?.zenotiId || ''}
                className={styles.therapistsSelect}
                optionSeparator
                disabledMessage='This therapist is already selected by another guest'
              />
              {isLoadingTherapists && <Spinner />}
            </div>
            )
          }
          </div>
          <div className={styles.rightCol}>
            <p className={styles.therapistTitle}>
              {Object.entries(guestsTherapists).map(([guestId, details]) => (
                <span key={guestId}>
                {details.therapist?.nickName 
                  ? details.therapist.nickName 
                  : details.gender && details.gender !== 'any'
                  ? `Any ${details.gender.charAt(0).toUpperCase() + details.gender.slice(1)} Therapist` 
                  : 'Any Therapist'}
                {', '}
                </span>
              ))}
            </p>
            <div className={styles.timeSlots}>
              {isCreateServiceBookingPending && <Spinner />}
              {!isCreateServiceBookingPending && slots
                .filter(slot => {
                  const selectedDate = dateValue instanceof Date ? dateValue.toISOString() : ''
                  const slotDate = DateTime.fromISO(slot.time).toFormat("yyyy-MM-dd");
                  return slotDate === DateTime.fromISO(selectedDate).toFormat("yyyy-MM-dd");
                })
                .map((slot, index) => {
                  const formattedTime = DateTime.fromISO(slot.time).toFormat("h:mm a");
                  return (
                    <div
                      className={`${styles.slot} ${selectedTimeSlot === slot.time ? styles.selected : ''}`}
                      key={slot.time + index}
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedTimeSlot(slot.time)
                        pushToDataLayer({
                          event: 'timeSelectionOnly',
                          workflowName: 'booking',
                          action: 'buttonClick',
                        })
                      }}
                    >
                      {formattedTime}
                    </div>
                  );
              })}
              {!isCreateServiceBookingPending && <div className={styles.callUs}>
                Not seeing the availability you want? Please call us at {formatPhoneNumber(boutique?.phoneNumber || '')}
              </div>}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.controls}>
        <div className={`${styles.controlsContainer} container`}>
          <div className={styles.controlButtons}>
            <button 
              onClick={handleConfirmReschedule}
              disabled={isSubmissionDisabled}
              className={`button ${styles.controlButton} ${isSubmissionDisabled? "disabled" : ""}`}
            >
              {isConfirmBookingPending ? <Spinner size={20}/> : "Reschedule Appointment"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescheduleAppointment;