import styles from '../styles/components/BookingFlowEnhancements.module.scss';
import { Service, ServiceRestriction } from '../types/bookings';
import ArrowExpandIcon from '../assets/icons/arrow-expand.svg';
import { useEffect, useState } from 'react';
import { useToast } from './core/ToastManager';

interface Props {
  services: Service[];
  selectedMassage?: Service;
  onSelectEnhancements: (enhancements: Service[]) => void;
  prevSelectedEnhancements?: Service[];
  isPregnant?: boolean;
  isDeepPressure?: boolean;
  isGroupBooking?: boolean;
  className?: string;
}

export const BookingFlowEnhancements: React.FC<Props> = ({
  services,
  selectedMassage,
  onSelectEnhancements,
  prevSelectedEnhancements,
  isPregnant,
  isDeepPressure,
  isGroupBooking,
  className
}) => {
  const [showEnhancementBenefits, setShowEnhancementBenefits] = useState<string | number>('');
  const [selectedEnhancements, setSelectedEnhancements] = useState<Service[]>(prevSelectedEnhancements || []);
  const filteredEnhancements = services.filter(service => selectedMassage?.addOnList.includes(service.zenotiId));
  const { addToast } = useToast();

  // Check restrictions based on API data
  const hasRestriction = (restrictions: ServiceRestriction[], type: string) =>
    restrictions.some(restriction => restriction.type === type);

  const isRestrictedEnhancement = (enhancement: Service) => {
    // Check restrictions from the selected massage
    const isMassageRestricted = selectedMassage?.restrictedServices.some(
      (restrictedService) => restrictedService.id === enhancement.id
    );
  
    // Check restrictions from previously selected enhancements
    const isEnhancementRestricted = selectedEnhancements.some((selectedEnhancement) => {
      return selectedEnhancement.restrictedServices.some((restrictedService) => {
        return restrictedService.id === enhancement.id;
      });
    });
    
    return (
      (isPregnant && hasRestriction(enhancement.restrictions, 'pregnancy_excluded')) ||
      (isDeepPressure && hasRestriction(enhancement.restrictions, 'heavy_pressure_excluded')) ||
      (isGroupBooking && hasRestriction(enhancement.restrictions, 'group_booking_excluded')) || 
      isMassageRestricted ||
      isEnhancementRestricted
    );
  };

  const isRequiredEnhancement = (enhancement: Service) => {
    return (
      (isPregnant && hasRestriction(enhancement.restrictions, 'pregnancy_required')) ||
      (isDeepPressure && hasRestriction(enhancement.restrictions, 'heavy_pressure_required'))
    );
  };

  const getRestrictionMessage = (enhancement: Service, restrictionType: 'excluded' | 'required') => {
    const restrictionKeys: string[] = [];
  
    // Determine the applicable restriction keys based on conditions
    if (restrictionType === 'excluded') {
      if (isPregnant) restrictionKeys.push('pregnancy_excluded');
      if (isDeepPressure) restrictionKeys.push('heavy_pressure_excluded');
      if (isGroupBooking) restrictionKeys.push('group_booking_excluded');
    } else if (restrictionType === 'required') {
      if (isPregnant) restrictionKeys.push('pregnancy_required');
      if (isDeepPressure) restrictionKeys.push('heavy_pressure_required');
    }
  
    // Find the first matching restriction message
    const matchingRestriction = enhancement.restrictions.find(restriction =>
      restrictionKeys.includes(restriction.type)
    );
  
    return matchingRestriction?.message;
  };
  
  useEffect(() => {
    // Auto-select required enhancements based on conditions
    const autoSelectedEnhancements = [...selectedEnhancements];
  
    filteredEnhancements.forEach((enhancement) => {
      if (
        (isPregnant && hasRestriction(enhancement.restrictions, 'pregnancy_required')) ||
        (isDeepPressure && hasRestriction(enhancement.restrictions, 'heavy_pressure_required'))
      ) {
        if (!autoSelectedEnhancements.some((e) => e.id === enhancement.id)) {
          autoSelectedEnhancements.push(enhancement);
        }
      }
    });
  
    if (autoSelectedEnhancements.length !== selectedEnhancements.length) {
      setSelectedEnhancements(autoSelectedEnhancements);
      onSelectEnhancements(autoSelectedEnhancements);
    }
  }, [isPregnant, isDeepPressure, filteredEnhancements]);
  
  useEffect(() => {
    // If `isDeepPressure` becomes false, remove auto-selected deep-pressure enhancements
    if (!isDeepPressure) {
      const updatedEnhancements = selectedEnhancements.filter(
        (enhancement) => !hasRestriction(enhancement.restrictions, 'heavy_pressure_required')
      );
  
      // Update the selected enhancements if any were removed
      if (updatedEnhancements.length !== selectedEnhancements.length) {
        setSelectedEnhancements(updatedEnhancements);
        onSelectEnhancements(updatedEnhancements);
      }
    }
  }, [isDeepPressure]);
  

  const handleEnhancementSelect = (enhancement: Service) => {
    if (isRestrictedEnhancement(enhancement)) {
      const restrictionMessage = getRestrictionMessage(enhancement, 'excluded');
      addToast(restrictionMessage || `${enhancement.name} cannot be selected due to your booking type.`, 'info');
      return;
    }
  
    // Determine if selection is limited by massage duration
    const isSelectionLimited = selectedMassage?.duration === 30;
  
    // Check if the enhancement is already selected
    const selectedItem = selectedEnhancements.find((e) => e.id === enhancement.id);
  
    if (!selectedItem) {
      // If selection is limited and the user has already selected 3 enhancements, prevent further selection
      if (isSelectionLimited && selectedEnhancements.length >= 3) {
        addToast('Only 3 enhancements may be added for a 25 minute service.', 'warning');
        return;
      }
  
      // Add the enhancement to the selection
      const updatedEnhancements = [...selectedEnhancements, enhancement];
      setSelectedEnhancements(updatedEnhancements);
      onSelectEnhancements(updatedEnhancements);
    } else {
      // Prevent unselecting required enhancements
      if (isRequiredEnhancement(enhancement)) {
        const requiredMessage = getRestrictionMessage(enhancement, 'required');
        addToast(requiredMessage || `${enhancement.name} cannot be unselected due to your condition.`, 'info');
        return;
      }
  
      // Remove the enhancement from the selection
      const filteredResults = selectedEnhancements.filter((item) => item.id !== selectedItem.id);
      setSelectedEnhancements(filteredResults);
      onSelectEnhancements(filteredResults);
    }
  };
  

  const handleShowBenefits = (event: React.MouseEvent, serviceId: string | number) => {
    event.stopPropagation();
    setShowEnhancementBenefits(serviceId === showEnhancementBenefits ? '' : serviceId);
  };

  return (
    <div className={`${styles.selectEnhancements} ${className ? className : ''}`}>
      {filteredEnhancements.map(enhancement => {
        const selectedItem = selectedEnhancements.find((e) => e.id === enhancement.id);
        const isLocked = isRequiredEnhancement(enhancement);
        const isRestricted = isRestrictedEnhancement(enhancement);

        return (
          <div
            className={`
              ${styles.enhancement} 
              ${selectedItem ? styles.selected : ''} 
              ${isLocked ? styles.locked : ''} 
              ${isRestricted ? styles.restricted : ''}
              ${className ? styles.popUpItem : ''}
              `}
            key={enhancement.id}
            onClick={() => handleEnhancementSelect(enhancement)}
          >
            <div className={styles.header}>
              <p className={styles.title}>{enhancement.name}</p>
              <p className={styles.price}>${enhancement.salePrice}</p>
            </div>
            <p className={`${styles.description} ${showEnhancementBenefits === enhancement.id ? styles.showFullDescription : ''}`}>{enhancement.description}</p>
            {enhancement.meta.benefits && (
              <>
                {showEnhancementBenefits === enhancement.id && (
                  <div>
                    <p className={styles.benefitsTitle}>Benefits:</p>
                    <p className={styles.benefitsDetails}>{enhancement.meta.benefits}</p>
                  </div>
                )}
                <button
                  className={`${styles.arrowButton} ${showEnhancementBenefits === enhancement.id ? styles.active : ''}`}
                  onClick={(e) => handleShowBenefits(e, enhancement.id)}
                >
                  <img src={ArrowExpandIcon} alt="expand info" />
                </button>
              </>
            )}
          </div>
        );
      })}
      {filteredEnhancements.length === 0 && (
        <p className={styles.noEnhancement}>No enhancements available for this service.</p>
      )}
    </div>
  );
};
