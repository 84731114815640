import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/pages/UserMemberships.module.scss';
import { useUserMemberships } from '../hooks/useUserProfile';
import Modal from '../components/core/Modal';
import MembershipCredits from '../components/core/MembershipCredits';
import Spinner from '../components/core/Spinner';
import { DateTime } from 'luxon';
import { useToast } from '../components/core/ToastManager';
import { UserMembership } from '../types/memberships';
import MembershipTermsModal from '../components/MembershipTermsModal';
import { DashboardCard } from '../components/DashboardCard';
import MemberIcon from '../assets/icons/become-a-member.svg';
import { numberToText } from '../utils/caseConverter';
import ErrorScreen from '../components/core/ErrorMessages';

export const UserMemberships = () => {
  const [showTermsModal, setShowTermsModal] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const isCancellable = userMemberships?.cancellable;

  if (isLoadingUserMemberships) return <Spinner />;
  
  if (userMembershipsError) return <ErrorScreen errors={[userMembershipsError]} />;

  function isMembershipExpired(userMembership: UserMembership) {
    const terminationDate = userMembership.terminationDate ? DateTime.fromISO(userMembership.terminationDate).startOf('day') : null;
    return terminationDate && terminationDate <= DateTime.now().startOf('day');
  }

  const activeMemberships = userMemberships?.membershipGroups
    .flatMap((group) => group.userMemberships.map((userMembership) => ({ ...userMembership, groupName: group.name })))
    .filter((membership) => !isMembershipExpired(membership));

  if (activeMemberships && activeMemberships.length) {
    return (
      <div className={styles.memberships}>
        {activeMemberships.map((userMembership, index) => {
          const membership = userMembership.membership;
          const creditsName = userMembership.groupName;
          const cancellationLink = `/settings/memberships/cancel/${membership.boutique.id}/${membership.zenotiId}/${userMembership.zenotiId}`;
          const isCancelled = ["cancelled", "closed", "expired"].includes(userMembership.status);
          const isFrozen = userMembership.status === "frozen";
          const terminationDate = userMembership.terminationDate 
            ? DateTime.fromISO(userMembership.terminationDate).toFormat("MMMM dd, yyyy")
            : null;
          const nextCollectionDate = membership.nextCollectionDate ? DateTime.fromISO(membership.nextCollectionDate).toFormat("MMMM dd, yyyy") : null;

          const handleCancel = () => {
            if (isCancellable) navigate(cancellationLink);
            else addToast('Membership cannot be cancelled online. Please contact your boutique for assistance!', 'error', 4000);
          };

          return (
            <div className={styles.membershipCard} key={index}>
              <h2>{membership.name}</h2>
              <ul className={styles.membershipDetails}>
                <li>{`${numberToText(membership.frequencyPerMonth || 0)} ${membership.durationInMinutes} minute ${membership.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month`}</li>
                <li>{membership.boutique.name}</li>
              </ul>
              <MembershipCredits name={`${creditsName} Credits:`} amount={membership.creditBalance || 0} />
              <ul className={styles.membershipDetails}>
                {nextCollectionDate && !isCancelled && !isFrozen && !terminationDate && (
                  <li>Auto-renews {nextCollectionDate} for ${membership.nextCollectionAmount}</li>
                )}
                {isFrozen && userMembership.unfreezeDate && (
                  <li>Your membership is paused until {DateTime.fromISO(userMembership.unfreezeDate).toFormat("MMMM dd, yyyy")}</li>
                )}
                {terminationDate && (
                  <li className={styles.terminationDate}>Membership expires {terminationDate}</li>
                )}
                <li className={styles.terms}>
                  Membership <button className={`button__link ${styles.termsLink}`} onClick={() => setShowTermsModal(true)}>Terms & Conditions</button>
                </li>
              </ul>

              {isCancelled || terminationDate ? (
                <Link to="/memberships" className={styles.cardButton}>Purchase a New Membership</Link>
              ) : isFrozen ? (
                <Link to={`/memberships/pause/${userMembership.zenotiId}`} className={styles.cardButton}>Resume Membership</Link>
              ) : (
                <>
                  <Link to={`/settings/memberships/update/${membership.boutique.id}/${membership.zenotiId}/${userMembership.zenotiId}`} className={styles.cardButton}>Change Membership</Link>
                  <Link to={`/memberships/pause/${userMembership.zenotiId}`} className={styles.cardButton}>Pause Membership</Link>
                  <button onClick={handleCancel} className={styles.cardButton}>Cancel Membership</button>
                </>
              )}

              <Modal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)}>
                <MembershipTermsModal membershipId={membership.zenotiId} boutiqueId={membership.boutique.id} />
              </Modal>
            </div>
          );
        })}
      </div>
    );
  }
  
  return (
    <DashboardCard className={styles.memberCard} iconUrl={MemberIcon} title="Become a Ritual Member">
      <div className={styles.memberCardContent}>
        <p>Commit to self-care and save on 50 or 80 minutes of relaxation every month.</p>
        <Link className="button" to="/memberships">
          Join Now
        </Link>
      </div>
    </DashboardCard>
  )
};

export default UserMemberships;
